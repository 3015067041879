








































































































































































































































































































































































































import { Component, Prop } from "vue-property-decorator";
import Bases from "@/components/designer/sidebar/base/Base.vue";
import Finish from "@/components/designer/sidebar/frame/Finish.vue";
import { mixins } from "vue-class-component";
import { BaseStore, LayoutStore, ShapeStore, FrameStore } from "@/mixins/store";
import { GetFinishImage } from "@/mixins/helpers";
import { BaseModel } from "@/models/products/base";
import {
  Finish as FinishModel,
  FinishHandle,
  FinishDisplayName
} from "@/models/products/finish";
import ProductService from "@/services/product_service";
import UIkit from "uikit";
import { ProductListRes, ProductType } from "@/models/products/product";
import { Collection, ProductLineName } from "@/models/products/collection";
import { APIError, ViewerError } from "@/services/error_service";
import { Spigot, Stem } from "@/models/configurator/configurator_options";
import { StockMixin } from "@/mixins/stock";
import base from "@/store/modules/configurator/base";
import { Accessory } from "@/models/products/accessory";

@Component({
  components: {
    Bases,
    Finish,
  },
})
export default class BaseSidebar extends mixins(
  BaseStore,
  LayoutStore,
  FrameStore,
  GetFinishImage,
  ShapeStore,
  StockMixin
) {
  @Prop({ default: true }) hasVent!: boolean;
  @Prop({ default: true }) hasValance!: boolean;
  protected ProductLineName = ProductLineName;
  protected selectedBase: BaseModel | null = null;
  protected selectedFinish: FinishModel | null = null;
  protected baseData: null | Collection[] = null;
  protected productService = new ProductService();
  protected FinishHandle = FinishHandle;
  protected selectedStem: Stem | null = null;
  protected selectedWheels: boolean | null = null;
  protected selectedSpigot!: Spigot;
  protected selectedTopCover: null | {
    model: string;
    finish: FinishModel;
  } = null;
  protected Stem = Stem;
  protected filteredBases = [
    "50C",
    "BZ-SM",
    "BZ2-SM",
    "8ST",
    "18ST",
    // "36G-SQx2",
    "XB",
    "XB+C",
    "NGU550-E",
    // "100S"
    // "38SAP-2",
  ]; // hide these bases

  async mounted() {
    const loader = this.$loading.show({
      isFullPage: false,
      container: this.$refs.spinnerContainer,
    });
    const data = await this.getBases(loader);
    if (!this.collection.specific_mounting) {
      this.baseData = data! as Collection[];
    } else {
      this.baseData = [
        {
          type: ProductType.Base,
          _id: "1",
          display_name: `${this.collection.display_name} bases & mounts`,
          handle: this.collection.handle,
          models: data! as BaseModel[],
        },
      ] as Collection[];
    }
    
    this.baseBusinessLogic();
    this.readStoreData();
  }
  



  protected verifyImageUrl(url: string): string | null {
    try {
      const request = new XMLHttpRequest();
      request.open('HEAD', url, false);
      request.send();
      if (request.status === 200) {
        return url;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  /** Specific logic to override base display on specific product lines */
  protected baseBusinessLogic(): void {
    if (this.collection.handle === ProductLineName.Catalina) {
      const availableBasesCatalina = {
        handle: "galvanized_steel_plate",
        models: ["20G-SQ", "24G-SQ", "24G", "30G"],
      };
      this.baseData = this.baseData!.map((base) => {
        if (base.handle === availableBasesCatalina.handle) {
          base.models = (base.models as BaseModel[]).filter((b: BaseModel) => {
            if (availableBasesCatalina.models.includes(b.model)) {
              return b;
            }
          }) as BaseModel[];
          return base as Collection;
        } else {
          return base as Collection;
        }
      });
    }
  }

  protected async clearBase(): Promise<void> {
    this.addBase(null);
    this.addBaseFinish(null);
    this.addBaseStem(null);
    this.addBaseOptions(null);
    this.selectedBase = null;
    this.selectedFinish = null;
    this.selectedStem = null;
    this.selectedWheels = null;
    this.selectedTopCover = null;
    await this.$viewer.ChangeBase(null);
  }

  /**
   * Check the store for preset data. If there is none, set default
   * selected model to the first one in model array
   */
  protected readStoreData(): void {
    this.selectedBase = this.baseModel;
    this.selectedFinish = this.baseFinish;
    this.selectedStem = this.baseStem;
    this.selectedWheels = this.baseOptions?.wheels ? true : false;
    this.selectedSpigot = this.spigot;
    this.selectedTopCover = this.baseOptions?.topCover
      ? this.baseOptions?.topCover
      : null;
  }

  protected setDefaultStem(model: BaseModel): void {
    if (this.selectedBase!.model === Stem.Stem18ST2) {
      this.selectedStem = Stem.Stem18ST2;
    } else if (model.mounts && model.mounts.length > 0) {
      this.addBaseStem(Stem.Stem8ST);
      this.selectedStem = Stem.Stem8ST;
    } else {
      this.addBaseStem(null);
      this.selectedStem = null;
    }
  }

  protected setDefaultFinish(model: BaseModel): void {
    const finish = model.finishes[0] ? model.finishes[0] : null;
    this.selectedFinish = finish;
    this.addBaseFinish(finish);
  }

  protected async getBases(
    loader?: any
  ): Promise<Collection[] | BaseModel[] | undefined> {
    let bases;
    let res: ProductListRes;
    try {
      if (this.collection.specific_mounting) {
        bases = [] as BaseModel[];
        res = await this.productService.getBases(this.umbrellaModel?.model);
        bases = (res.data! as any).bases as BaseModel[];
        bases = bases.filter(
          (model) => !this.filteredBases.includes(model.model)
        );
      } else {
        bases = [] as Collection[];
        res = await this.productService.getBases();
        bases = res.data!.filter(
          (pl: any) =>
            pl.type === ProductType.Base &&
            pl.handle !== "aluminum_shell" &&
            pl.handle !== "specialty" &&
            pl.handle !== "in_ground_direct_mount"
        );
        for (const [i, pl] of (bases as Collection[]).entries()) {
          let models: BaseModel[] = [...pl.models] as BaseModel[];
          models = models.filter(
            (model) =>
              !model.is_umbrella_specific &&
              !this.filteredBases.includes(model.model)
          );
          (bases as Collection[])[i].models = models;
        }
      }
      if (loader) {
        loader.hide();
      }
    } catch (err) {
      if (err instanceof APIError) {
        APIError.popup(err.message, err.statusCode);
      }
    }
    return bases;
  }

  protected hasVisibleStemGroup(baseModel: BaseModel | any): boolean {
    if (
      this.collection.handle === ProductLineName.Aurora &&
      (baseModel as BaseModel).model === "36G-SQx2"
    ) {
      return false;
    }
    return true;
  }

  protected async selectBase(
    model: BaseModel,
    finish: FinishModel | null = null,
    stem: Stem | null = null,
    wheels: boolean | null = null,
    topCover: { model: string; finish: FinishModel } | boolean | null = null
  ): Promise<void> {
    this.selectedBase = model;
    let baseOptions: any = {};
    if (!stem) {
      this.setDefaultStem(model);
    } else {
      this.selectedStem = stem;
      this.addBaseStem(stem);
    }
    if (!finish || !model.finishes.includes(finish)) {
      this.setDefaultFinish(model);
    } else {
      this.selectedFinish = finish;
      this.addBaseFinish(finish);
    }
    // @ts-ignore
    if (model.attributes && model.attributes.wheels && !wheels) {
      this.selectedWheels = false;
      // @ts-ignore
    } else if (model.attributes && model.attributes.wheels && wheels) {
      this.selectedWheels = true;
      baseOptions.wheels = this.selectedWheels;
    } else {
      this.selectedWheels = null;
    }
    // @ts-ignore
    if (model.attributes && model.attributes.topCover && topCover) {
      if (
        topCover === true ||
        (this.baseModel && this.baseModel?._id !== model._id)
      ) {
        // set a default value
        const selectedBaseCover = this.selectedBaseTopCover(model);
        if (!selectedBaseCover) {
          this.selectedTopCover = null;
        } else if (
          this.selectedTopCover &&
          this.selectedTopCover.model === selectedBaseCover.model
        ) {
          // handling repeat button clicks to toggle top cover
          return;
        } else if (this.selectedTopCover) {
          // keep the selected finish, only change the model of the top cover
          this.selectedTopCover.model = selectedBaseCover.model;
        } else {
          this.selectedTopCover = {
            model: selectedBaseCover.model,
            finish: {
              handle: FinishHandle.WG,
              display_name: FinishDisplayName.WG
            }
          };
        }
      } else {
        // topCover is the populated object
        this.selectedTopCover = topCover;
      }
      baseOptions.topCover = this.selectedTopCover;
    } else {
      this.selectedTopCover = null;
    }
    await this.addBaseOptions(baseOptions);
    await this.addBase(model);
    await this.sendToViewer(
      typeof wheels === "boolean" ? wheels : null,
      topCover === false ? false : baseOptions.topCover
    );
  }

  protected selectedBaseTopCover(model: BaseModel): Accessory | undefined {
    if (!model.accessories) return undefined;
    return model.accessories.find(accessory => accessory.model.endsWith("-TC"));
  }

  protected async sendToViewer(
    wheels: boolean | null,
    topCover: false | any | null = null
  ): Promise<void> {
    let base;
    //Certain base+18ST2 combinations are a separate product with no stem attribute. Detect these bases and set selectedStem to 18ST2 & trim from model name (this is how the viewer accepts this data)
    if (
      this.selectedBase!.model &&
      this.selectedBase!.model !== Stem.Stem18ST2 &&
      this.selectedBase!.model.indexOf("18ST2") > -1
    ) {
      base = this.selectedBase!.model.slice(0, -6);
      this.selectedStem = Stem.Stem18ST2;
    } else if (!this.selectedBase!.model) {
      base = null;
    } else {
      base = this.selectedBase!.model;
    }
    try {
      if (this.selectedBase) {
        const stem = this.selectedStem;
        const finish = this.selectedFinish
          ? this.selectedFinish.handle
          : this.selectedBase!.finishes[0].handle;
        const stemFinish = FinishHandle.SS; // Stems 8ST 18ST & 18ST2 all have stainless steel finishes no matter what color the base is in.
        await this.$viewer.ChangeBase(base, finish, stem, stemFinish);
        if (base) {
          if (typeof wheels === "boolean") {
            await this.$viewer.ToggleBaseWheels(wheels);
          }
          if (topCover === false) {
            await this.$viewer.TogglePlateCover(false);
          } else if (topCover) {
            await this.$viewer.TogglePlateCover(true, topCover.finish.handle);
          }
        }
      }
    } catch (err) {
      // console.log(err);
      ViewerError.redirect(err);
    }
  }

  protected toggleBaseModal(id: string): void {
    UIkit.modal(document.getElementById(id) as HTMLElement).show();
  }
  protected getImage(
    model: BaseModel,
    selectedBase: BaseModel | null,
    finish: FinishModel | null,
    selectedStem: Stem | null
  ): string {
    let imageUrl = (model as any).image as string;

    // Base URL setup
    const cdnUrls = (process.env.VUE_APP_CDN_URL_OPTIONS || "").split(",");
    const matchedUrl = cdnUrls.find((url: string) => imageUrl.includes(url)) || process.env.VUE_APP_CDN_URL;

    if (!matchedUrl) {
      return imageUrl;
    }

    // Replace matched CDN URL or handle relative URLs
    if (imageUrl.includes(matchedUrl)) {
      imageUrl = imageUrl.replace(matchedUrl, process.env.VUE_APP_CDN_URL || matchedUrl);
    } else if (!imageUrl.startsWith("http")) {
      imageUrl = `${process.env.VUE_APP_CDN_URL}/products/bases/${imageUrl}`;
    }

    // Default image handling for unselected bases
    if (!selectedBase || selectedBase._id !== model._id) {
      return imageUrl;
    }

    const baseName = model.model.toUpperCase();
    let additionalSuffix = "";

    // Handle special cases for base-specific or umbrella-specific suffix logic
    if (baseName === "480") {
      additionalSuffix = this.get480Suffix(model, finish, selectedStem);
    } else if (["NGU-550", "XBC"].includes(baseName)) {
      additionalSuffix = this.getSpigotSuffix(baseName, finish);
    } else {
      // Add finish and stem for general bases
      const finishSuffix = finish ? `-${String(finish.handle).toUpperCase()}` : "";
      const stemSuffix = selectedStem ? `-${String(selectedStem).toUpperCase()}` : "";
      additionalSuffix = `${finishSuffix}${stemSuffix}`;
    }

    // Remove the hyphen for NGU-550 in filenames
    const formattedBaseName = baseName === "NGU-550" ? baseName.replace("-", "") : baseName;

    const constructedUrl = `${process.env.VUE_APP_CDN_URL}/products/bases/${formattedBaseName}${additionalSuffix}.jpg`;

    return this.verifyImageUrl(constructedUrl) || imageUrl;
  }

protected getSpigotSuffix(baseName: string, finish: FinishModel | null): string {
  const umbrellaType = this.collection.handle as ProductLineName;
  let umbrellaSpecificPrefix = "";

  if (umbrellaType === ProductLineName.Eclipse && baseName === "NGU-550") {
    umbrellaSpecificPrefix = "_ECU";
  } else if (umbrellaType === ProductLineName.Aurora && baseName === "XBC") {
    umbrellaSpecificPrefix = "_ARU";
  }

  let spigotSuffix = "";
  if (this.selectedSpigot === Spigot.StainlessSteel) {
    spigotSuffix = "-SP-SS";
  } else if (this.selectedSpigot === Spigot.Standard) {
    spigotSuffix = "-SP-SM";
  }

  const finishSuffix = finish ? `-${String(finish.handle).toUpperCase()}` : "";

  return `${finishSuffix}${umbrellaSpecificPrefix}${spigotSuffix}`;
}

protected get480Suffix(model: BaseModel, finish: FinishModel | null, selectedStem: Stem | null): string {
  const umbrellaType = this.collection.handle as ProductLineName;

  const isMontereyOrGreenwich = 
    umbrellaType === ProductLineName.MontereyGSeries || umbrellaType === ProductLineName.GreenwichGSeries;

  const isAuroraOrEclipse = 
    umbrellaType === ProductLineName.Aurora || umbrellaType === ProductLineName.Eclipse;


  if (isMontereyOrGreenwich) {
    return `-${String(finish?.handle).toUpperCase()}-${selectedStem ? String(selectedStem).toUpperCase() : "18ST2"}`;
  } else if (isAuroraOrEclipse) {
    return `-${String(finish?.handle).toUpperCase()}`;
  }

  return ""; 
}

protected getSpecialCaseSuffix(
  baseName: string,
  finish: FinishModel | null,
  selectedStem: Stem | null
): string {
  const specialCases = ["eclipse", "aurora"];
  const isSpecialCase = specialCases.includes(this.collection.handle.toLowerCase());
  if (!isSpecialCase) return "";

  let spigotPrefix = "";

  if (baseName === "NGU550") {
    spigotPrefix = "_ECU";
  } else if (baseName === "XBC") {
    spigotPrefix = "_ARU";
  }

  let spigotSuffix = "";
  if (this.selectedSpigot === Spigot.StainlessSteel) {
    spigotSuffix = "-SP-SS";
  } else if (this.selectedSpigot === Spigot.Standard) {
    spigotSuffix = "-SP-SM";
  }

  return `${spigotPrefix}${spigotSuffix}`;
}
protected isSpecialCaseBase(): boolean {
  const specialCases = ["eclipse", "ngu550", "aurora", "xbc"];
  return specialCases.includes(this.collection.handle.toLowerCase());
}

}
